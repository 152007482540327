// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-overview-management-cycle-content[data-v-24acad26] {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 12px;
}
.promotion-overview-management-cycle-content .text[data-v-24acad26] {
  font-family: PingFang SC, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.84);
}
.promotion-overview-management-cycle-content .text p[data-v-24acad26] {
  margin: 0;
}
.promotion-overview-management-cycle-content .jumpButton[data-v-24acad26] {
  margin-left: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/CycleContent/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,SAAS;AACX;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".promotion-overview-management-cycle-content[data-v-24acad26] {\n  display: flex;\n  justify-content: space-between;\n  margin-left: 16px;\n  margin-right: 16px;\n  margin-bottom: 12px;\n}\n.promotion-overview-management-cycle-content .text[data-v-24acad26] {\n  font-family: PingFang SC, sans-serif;\n  font-size: 12px;\n  line-height: 18px;\n  text-align: justify;\n  color: rgba(0, 0, 0, 0.84);\n}\n.promotion-overview-management-cycle-content .text p[data-v-24acad26] {\n  margin: 0;\n}\n.promotion-overview-management-cycle-content .jumpButton[data-v-24acad26] {\n  margin-left: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
