// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-overview-management-header[data-v-45c6d806] {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}
.promotion-overview-management-header .monthUrl[data-v-45c6d806] {
  width: 86px;
  height: 28px;
  position: absolute;
  top: 12px;
  right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":[".promotion-overview-management-header[data-v-45c6d806] {\n  padding-top: 16px;\n  padding-left: 16px;\n  padding-right: 16px;\n  position: relative;\n}\n.promotion-overview-management-header .monthUrl[data-v-45c6d806] {\n  width: 86px;\n  height: 28px;\n  position: absolute;\n  top: 12px;\n  right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
