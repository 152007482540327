// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant5-spin-nested-loading {
  flex: 1;
}
.ant5-spin-nested-loading .ant5-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/style.less"],"names":[],"mappings":"AAAA;EACE,OAAO;AACT;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".ant5-spin-nested-loading {\n  flex: 1;\n}\n.ant5-spin-nested-loading .ant5-spin-container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
