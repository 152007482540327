import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // get请求示例
  getRequestData: async (host, params) => {
    const result = await PageletApi.request(`${host}/${apis.getList}`, {
      method: 'get',
      params
    });

    return result;
  },

  // post请求示例
  getRequestData: async (host, data) => {
    const result = await PageletApi.request(`${host}/${apis.getList}`, {
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return result;
  },

  getDept: async (host, params) => {
    const request = PageletApi.newRequest.getInstance();

    const result = await request(`${host}/${apis.getDept}`, {
      method: 'get',
      params
    });

    return result;
  },

  // post 初始化接口
  postManager: async (host, headers, data) => {
    const result = await PageletApi.request(`${host}/${apis.postManager}`, {
      method: 'post',
      headers,
      data
    });
    return result;
  },

  // post 搜索人员
  postSearch: async (host, headers, data) => {
    const request = PageletApi.newRequest.getInstance();
    const result = await request(`${host}/${apis.postSearch}`, {
      method: 'post',
      headers,
      data
    });
    return result;
  },


  // post 搜索和柱状图
  postSearchAndDistribution: async (host, headers, data) => {
    const request = PageletApi.newRequest.getInstance();
    const result = await request(`${host}/${apis.postSearchAndDistribution}`, {
      method: 'post',
      headers,
      data,
      
    });
    return result;
  }
}

export default PageletServices;
