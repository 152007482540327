// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagelet-promotion-overview-management[data-v-86719e4e] {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url(//img-ys011.didistatic.com/static/ddo_web_static/do1_mmp4DtcHcKTP1VEcMsqd);
  font-size: 12px;
  background-size: 100% 100%;
  position: relative;
  border-radius: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gGAAgG;EAChG,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".pagelet-promotion-overview-management[data-v-86719e4e] {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background-image: url(//img-ys011.didistatic.com/static/ddo_web_static/do1_mmp4DtcHcKTP1VEcMsqd);\n  font-size: 12px;\n  background-size: 100% 100%;\n  position: relative;\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
