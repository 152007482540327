import React, { useEffect, useReducer, useMemo } from 'react';
import { ConfigProvider } from '@didi/ec-base';
import { utils, newRequest } from '@didi/pagelets-sdk';
import PageletServices from './services';
import PageHeader from './components/PageHeader'; // 页面头部
import PageContent from './components/PageContent'; // 页面内容
import PageBottom from './components/PageBottom'; // 页面底部
import { enumText } from './components/PageContent/components/FilterContent/utils';
import classNames from 'classnames';
import { PublicStore, getApolloConfig } from './utils';
import './style.scoped.less';

const Pagelet = props => {
  const {
    params: { apiHost, auth, headers = {}, deptCodes = [] },
  } = props;

  const { functionId, functionName } = utils.getFunctionData(auth, '@didi/pagelet-promotion-overview-management') || {}; // 获取组件的id
  // 配置请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  // 初始化请求头
  newRequest.init(newHeaders);

  // 全局数据
  const [stateStore, stateDispatch] = useReducer(
    (stateStore, action) => {  // 返回及合并数据
      return {
        ...stateStore,
        ...action
      };
    }, {
    apolloConfig: { // apollo 配置信息
      pageLetInfo: {
        tipText: []
      }, // 组件的信息
      helpInfo: {}, // 帮助信息
      monthUrl: '', // 月份url
      cycleUrl: '', // 周期url
    },
    newHeaders,
    resultLoading: false, // 请求是否加载中
    // 筛选条件
    staffChildSearchTypes: [],
    // 搜索人员
    searchValue: '',
    searchName: '',
    // 筛选条件
    searchType: '',
    searchText: '',
    // 选中状态
    distributionCode: '',
    distributionName: '',
    // 状态图数据
    distributions: [],
    totalNum: 0,
    // 人员列表
    searchLoading: false,
    pageNum: 0,
    list: [],
    hasMore: true,
  });

  // 页面数据
  const contextValue = useMemo(
    () => ({
      // 存储全局数据
      stateStore,
      stateDispatch,
      props
    }),
    [stateStore]
  );

  // 初始化接口请求
  const handleInitRequest = async () => {
    try {
      const res = await PageletServices.getDept('', {});
      const { data } = await PageletServices.postManager('', newHeaders, {
        deptCodes,
        topDeptCodes: res?.data.map(item => item?.deptCode),
      });
      const { progressDistribution, searchParam, pageResult, staffChildSearchTypes } = data;
      const { distributions, totalNum, } = progressDistribution;
      const { distributionCode } = searchParam;
      const findType = staffChildSearchTypes.find(item => item.selected);
      stateDispatch({
        ...searchParam, // 筛选条件
        distributions, // 状态图
        totalNum, // 柱状图数量
        staffChildSearchTypes: staffChildSearchTypes.map(item => item.searchType), // 筛选条件
        searchText: enumText[findType?.searchType],
        distributionName: distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        loading: false,
      });
    } catch (e) {
      stateDispatch({ loading: false });
      console.log(e);
    }
  };

  // 接口请求示例
  useEffect(async () => {
    handleInitRequest();
    const res = await getApolloConfig(apiHost); // 获取apollo配置
    stateDispatch({ apolloConfig: res });
  }, []);

  const prefixCls = 'pagelet-promotion-overview-management';
  const classes = classNames(prefixCls);

  return (
    <ConfigProvider prefixCls="ant5">
      <PublicStore.Provider value={contextValue}>
        <div className={classes}>
          <PageHeader />
          <PageContent />
          <PageBottom />
        </div>
      </PublicStore.Provider>
    </ConfigProvider>
  );
};

export default Pagelet;
